<template>
  <div class="dashboard-express-quotation-estimate-price tw-flex tw-flex-col tw-leading-snug tw-pl-0">
    <div
      class="tw-flex tw-items-center"
    >
      <span
        class="dashboard-express-quotation-estimate-price__name tw-font-bold tw-text-base tw-mr-2"
        v-text="name"
        data-test="name"
      />
      <ui-material-icon
        v-if="info"
        class="dashboard-express-quotation-estimate-price__icon tw-hidden md:tw-inline-block tw-text-base tw-mb-1"
        v-b-tooltip.hover
        :title="info"
        name="info"
        data-test="info"
      />
    </div>
    <div class="tw-flex">
      <div
        class="tw-text-blue-500 tw-font-light tw-text-lg tw-text-3xl"
        :class="{ 'tw-pr-2 border-r tw-border-blue-500' : isOfferCustomisable }"
        v-text="$t('price_excl_tax', {
          price: $options.filters.currency(price, currency, $i18n.locale, true)
        })"
        data-test="price"
      />
      <div
        v-if="isOfferCustomisable"
        @click="showCustomOfferModal"
        class="tw-text-blue-500 tw-font-light tw-text-md tw-pl-2 tw-underline tw-cursor-pointer tw-flex tw-items-center"
        data-test="custom-offer-modal"
      >
        {{ $t('new-shipment.custom_offer.button') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - DashboardExpressQuotationEstimatePrice
   * @param {string} name
   * @param {string} currency - Currency code
   * @param {number} price - The price in cents
   * @param {string} [info=null] - The textual information in the tooltip
   * @param {boolean} isOfferCustomisable - To know if offer is customisable or not
   */
  export default {
    name: 'DashboardExpressQuotationEstimatePrice',
    props: {
      name: {
        type: String,
        required: true
      },
      currency: {
        type: String,
        required: true
      },
      info: {
        type: String,
        default: null
      },
      price: {
        type: Number,
        required: true
      },
      isOfferCustomisable: {
        type: Boolean,
        required: true
      }
    },
    methods: {
      showCustomOfferModal () {
        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'customOffer'
        })

        if (this.$matomo) {
          this.$matomo.trackEvent('Estimate', 'Custom Offer - Details Clicked')
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dashboard-express-quotation-estimate-price {
    &__name,
    &__icon {
      color: $secondary-text;
    }
  }

  .border-r {
    border-right: 1px solid;
  }

</style>
