<template>
  <ui-button
    :to="{
      name: 'NewShipmentAddress',
      params: {
        direction: 'pickup'
      }
    }"
    class="tw-text-white tw-mt-4 xl:tw-mt-0"
    variant="primary"
    data-test="refine-button"
    @click.native="refine"
  >
    <slot />
  </ui-button>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: 'CtkBtnQuotationRefine',
    computed: {
      ...mapGetters('shipments', [
        'getEstimation',
        'getExpressQuoteAddress',
        'getExpressQuoteLoad'
      ])
    },
    methods: {
      ...mapActions('shipments/new-shipment', [
        'setAddress',
        'setGoods',
        'setPallets',
        'setContact',
        'setComment',
        'setTimeslot',
        'setHandlingDriver',
        'setHandlingTailLift'
      ]),
      refine () {
        if (this.$matomo) {
          const prices = this.getEstimation.prices && this.getEstimation.prices.map(price => price.type).join('|')
          this.$matomo.trackEvent('Estimate', 'Transform In Quotation', prices)
        }

        const load = this.getExpressQuoteLoad
        if (load) {
          const { quantity, format, length, width } = load
          this.setGoods(load)
          this.setPallets([
            {
              quantity,
              format,
              length,
              width
            }
          ])

          /**
           * Check if we have a full address from the estimation form.
           * If we have, then set it.
           * Otherwise, set only the country
           */
          const directions = ['pickup', 'delivery']
          directions.forEach(direction => {
            const directionData = this.getExpressQuoteAddress[direction]
            if (directionData.address.streetName) {
              const {
                companyName,
                source,
                address
              } = directionData

              const {
                streetName,
                city,
                postalCode,
                country,
                location
              } = address

              const hasCompanyName = !!companyName

              const addressToSave = {
                name: companyName,
                street_name: streetName || companyName,
                postal_code: postalCode,
                city,
                country,
                location: {
                  lat: location.lat,
                  lng: location.lng
                },
                hadCustomName: !hasCompanyName
              }

              /**
               * Go fetch the contact information inside the source object, since
               * the "address" here is an AddressComponent.
               */
              if (source && source.address && source.address.contact) {
                addressToSave.contact = source.address.contact
              }

              this.setAddress({
                direction,
                address: addressToSave
              })

              /**
               * Check if the source is present (in the case of a Ctk address).
               * Otherwise ignore.
               */
              if (directionData.source) {
                const {
                  comment,
                  contact,
                  handling,
                  start_time: startTime,
                  end_time: endTime
                } = directionData.source

                if (contact) {
                  this.setContact({
                    direction,
                    contact
                  })
                }

                this.setComment({
                  direction,
                  comment
                })

                if (handling) {
                  /**
                   * TODO: Change this behaviour and use a boolean instead.
                   */
                  this.setHandlingDriver({
                    direction: direction,
                    value: handling.driver ? 'carrier' : 'shipper'
                  })
                  this.setHandlingTailLift({
                    direction: direction,
                    value: handling.tail_lift ? 'yes' : 'no'
                  })
                }

                if (startTime && endTime) {
                  this.setTimeslot({
                    direction: direction,
                    startTime: this.$moment.duration(startTime).asMinutes(),
                    endTime: this.$moment.duration(endTime).asMinutes()
                  })
                }
              }
            } else {
              /**
               * Set only the country from the estimation to the shipment form
               * since we only have this data available yet.
               */
              this.setAddress({
                direction,
                address: {
                  country: directionData.address.country
                }
              })
            }
          })
        }
      }
    }
  }
</script>
