<template>
  <ValidationObserver
    ref="observer"
    slim
  >
    <form
      class="dashboard-express-quotation-form-goods-dropdown"
      data-test="form"
      @submit.prevent="confirm"
      @keydown="checkHide"
    >
      <ValidationProvider
        ref="type-provider"
        :name="$t('dashboard.fields.quotation.goods.type')"
        :rules="'required'"
        slim
        data-test="type-provider"
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-select
            v-model="formData.type"
            :label="$t('dashboard.labels.quotation.goods.type')"
            :items="types"
            :error="invalid && validated || errors && errors.length > 0"
            id="type"
            name="type"
            data-test="type"
            required
            class="mb-3"
          />
        </template>
      </ValidationProvider>

      <template v-if="formData.type === 'pallets' || formData.type === null">
        <ValidationProvider
          ref="quantity-provider"
          :name="$t('app.labels.load.quantity')"
          :rules="`required|min_value:1|max_value:${maxQuantity}`"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-select
              v-model="formData.quantity"
              :label="$t('app.labels.load.quantity') | capitalize"
              :items="quantities"
              :value-to-show="formData.quantity"
              :error="invalid && validated || errors && errors.length > 0"
              :disabled="formData.type === null"
              id="quantity"
              name="quantity"
              class="tw-flex-1 mb-2"
              data-test="quantity"
              required
            />
          </template>
        </ValidationProvider>
        <ValidationProvider
          ref="format-provider"
          :name="$t('app.labels.load.format')"
          :rules="'required'"
          slim
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-select
              v-model="formData.format"
              :label="$t('app.labels.load.format') | capitalize"
              :items="formats"
              :value-to-show="formData.format"
              :error="invalid && validated || errors && errors.length > 0"
              :disabled="formData.type === null"
              id="format"
              name="format"
              class="tw-flex-1 mb-2"
              data-test="format"
              required
            />
          </template>
        </ValidationProvider>
      </template>
      <template v-else>
        <ValidationProvider
          ref="length-provider"
          :name="$t('dashboard.fields.quotation.goods.length')"
          :rules="'required|min_value:1|max_value:1320|numeric'"
          tag="div"
          class="tw-flex mb-2"
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              id="length"
              v-model="formData.length"
              :error="invalid && validated || errors && errors.length > 0"
              :label="$t('dashboard.labels.quotation.goods.length')"
              :disabled="formData.type === null"
              name="length"
              type="tel"
              suffix="cm"
              class="tw-flex-1"
              data-test="length"
              required
            />
            <dashboard-express-quotation-form-goods-dropdown-help
              :has-error="invalid && validated || errors && errors.length > 0"
              :title="$t('dashboard.labels.quotation.goods.length')"
              :content="$t('dashboard.paragraphs.quotation.length_info')"
            />
          </template>
        </ValidationProvider>
        <ValidationProvider
          ref="width-provider"
          :name="$t('dashboard.fields.quotation.goods.width')"
          :rules="'required|min_value:1|max_value:240|numeric'"
          tag="div"
          class="tw-flex mb-2"
        >
          <template slot-scope="{ invalid, validated, errors }">
            <ctk-input-text
              id="width"
              v-model="formData.width"
              :error="invalid && validated || errors && errors.length > 0"
              :label="$t('dashboard.labels.quotation.goods.width')"
              :disabled="formData.type === null"
              name="width"
              type="tel"
              suffix="cm"
              class="tw-flex-1"
              data-test="width"
              required
            />
            <dashboard-express-quotation-form-goods-dropdown-help
              :has-error="invalid && validated || errors && errors.length > 0"
              :title="$t('dashboard.labels.quotation.goods.width')"
              :content="$t('dashboard.paragraphs.quotation.width_info')"
            />
          </template>
        </ValidationProvider>
      </template>

      <ValidationProvider
        ref="height-provider"
        :name="$t('app.labels.load.max_height')"
        :rules="'required|min_value:1|max_value:270|numeric'"
        tag="div"
        class="tw-flex mb-2"
        data-test="height-provider"
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="height"
            v-model="formData.height"
            :error="invalid && validated || errors && errors.length > 0"
            :label="$t('app.labels.load.max_height') | capitalize"
            :disabled="formData.type === null"
            name="height"
            type="tel"
            suffix="cm"
            class="tw-flex-1"
            data-test="height"
            required
          />
          <dashboard-express-quotation-form-goods-dropdown-help
            :has-error="invalid && validated || errors && errors.length > 0"
            :title="$t('app.labels.load.max_height') | capitalize"
            :content="$t(formData.type === 'pallets'
              ? 'dashboard.paragraphs.quotation.height_info_pallet'
              : 'dashboard.paragraphs.quotation.height_info_custom')"
            data-test="height-help"
          />
        </template>
      </ValidationProvider>
      <ValidationProvider
        ref="weight-provider"
        :name="$t('dashboard.fields.quotation.goods.weight')"
        :rules="'required|min_value:50|max_value:28000|numeric'"
        tag="div"
        class="tw-flex mb-2"
        data-test="weight-provider"
      >
        <template slot-scope="{ invalid, validated, errors }">
          <ctk-input-text
            id="weight"
            v-model="formData.weight"
            :error="invalid && validated || errors && errors.length > 0"
            :label="$t('dashboard.labels.quotation.goods.weight')"
            :disabled="formData.type === null"
            name="weight"
            type="tel"
            class="tw-flex-1"
            suffix="kg"
            data-test="weight"
            required
          />
          <dashboard-express-quotation-form-goods-dropdown-help
            :has-error="invalid && validated || errors && errors.length > 0"
            :title="$t('dashboard.labels.quotation.goods.weight')"
            :content="$t('dashboard.paragraphs.quotation.weight_info')"
            data-test="weight-help"
          />
        </template>
      </ValidationProvider>

      <div class="tw-flex tw-items-end pt-4">
        <ui-button
          :title="$t('validate') | capitalize"
          :disabled="$wait.is('fetching estimate price')"
          :loading="$wait.is('fetching estimate price')"
          type="submit"
          variant="primary"
          class="dashboard-express-quotation-form-goods-dropdown__validate"
          data-test="submit"
        >
          <template #left-icon>
            <ui-material-icon
              name="check"
              data-test="icon"
            />
          </template>
        </ui-button>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { AUTHORIZED_PALLET_FORMATS, KEYCODES } from '@/composables/constants'
  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkInputSelect from '@/components/CtkInputs/CtkInputSelect/index.vue'
  import DashboardExpressQuotationFormGoodsDropdownHelp from './_subs/DashboardExpressQuotationFormGoodsDropdownHelp/index.vue'

  const defaultFormData = {
    type: null,
    quantity: null,
    format: null,
    height: null,
    weight: null,
    width: null,
    length: null
  }

  /**
   * @module component - DashboardExpressQuotationFormGoodsDropdown
   */
  export default defineComponent({
    name: 'DashboardExpressQuotationFormGoodsDropdown',
    components: {
      CtkInputText,
      CtkInputSelect,
      DashboardExpressQuotationFormGoodsDropdownHelp
    },
    props: {
      value: {
        type: Object,
        default: null
      }
    },
    mounted () {
      this.formData = Object.assign(JSON.parse(JSON.stringify(defaultFormData)), this.value)
    },
    data () {
      return {
        formData: JSON.parse(JSON.stringify(defaultFormData))
      }
    },
    computed: {
      types () {
        return [
          {
            value: 'pallets',
            text: this.$t('dashboard.values.quotation.goods.type.pallet')
          },
          {
            value: 'custom',
            text: this.$t('dashboard.values.quotation.goods.type.custom')
          }
        ]
      },
      formats () {
        return Object.keys(AUTHORIZED_PALLET_FORMATS).map(key => ({
          value: key,
          text: key
        }))
      },
      quantities () {
        return Array.from(new Array(this.maxQuantity))
          .map((_, k) => ({
            value: k + 1,
            text: k + 1
          }))
      },
      /**
       * Returns the maximum quantity according to the format
       * @function maxQuantity
       */
      maxQuantity () {
        const { format } = this.formData
        switch (format) {
        case '120x100':
          return 26
        case '120x80':
          return 33
        case '120x120':
        case '114x114':
          return 22
        default:
          return 66
        }
      }
    },
    methods: {
      close () {
        this.$emit('close')
      },
      checkHide (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.ESC:
          e.preventDefault()
          this.close()
          this.$emit('re-focus')
          break
        }
      },
      confirm () {
        if (!this.$refs.observer) return

        this.$refs.observer.validate()
          .then(valid => {
            if (valid) {
              this.$emit('validate', this.formData)
              this.$emit('re-focus')
              this.close()
            }
          })
      }
    }
  })
</script>

<style lang="scss" scoped>
.dashboard-express-quotation-form-goods-dropdown {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-top: 0.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1.25rem;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0/0.1),0 2px 4px -2px rgb(0 0 0/0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  z-index: 10;
}
.dashboard-express-quotation-form-goods-dropdown:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
</style>

<style lang="scss">

  .dashboard-express-quotation-form-goods-dropdown .dashboard-express-quotation-form-goods-dropdown__validate span {
    display: flex;
    margin: auto;
  }

</style>
