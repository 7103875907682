import BaseAddressProvider from './BaseAddressProvider'
import ChronotruckAddressProvider from './ChronotruckAddressProvider'
import GoogleAddressProvider from './GoogleAddressProvider'

export default class EstimationAddressProvider extends BaseAddressProvider {
  constructor () {
    super()

    this.chronotruckProvider = new ChronotruckAddressProvider()
    this.googleProvider = new GoogleAddressProvider()
  }

  /**
   * Returns a list of predictions according to a query
   * @method getPredictions
   * @param {string} query
   * @param {string} type - Either "pickup" or "delivery"
   * @returns {Promise<Array>} result
   */
  getPredictions (query, type) {
    return new Promise(resolve => {
      Promise.all([
        this.chronotruckProvider.getPredictions(query, type),
        this.googleProvider.getPredictions(query)
      ])
        .then(response => {
          const predictions = [
            ...response[0].slice(0, 3),
            ...response[1]
          ]

          /**
           * Re-order the predictions to follow the order:
           * - Chronotruck
           * - Google
           */
          const providerOrder = [
            'ChronotruckAddressProvider',
            'GoogleAddressProvider'
          ]
          resolve(predictions.sort((a, b) => {
            const aIndex = providerOrder.findIndex(v => v === a.provider)
            const bIndex = providerOrder.findIndex(v => v === b.provider)
            return aIndex - bIndex
          }))
        })
    })
  }

  /**
   * Returns a detailed address item
   * @method getItem
   * @param {Object} prediction
   * @param {string} type - Either "pickup" or "delivery"
   * @param {Array} predictionsBag - A list of custom predictions to search for (only Ctk)
   * @returns {Promise<AddressComponent|Error>} result
   */
  getItem (prediction, type, predictionsBag = null) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let item
        if (prediction.provider === 'ChronotruckAddressProvider') {
          item = await this.chronotruckProvider.getItem(prediction.id, type, predictionsBag)
        }
        if (prediction.provider === 'GoogleAddressProvider') {
          item = await this.googleProvider.getItem(prediction.id)
        }

        resolve(item)
      } catch (e) {
        reject(e)
      }
    })
  }

  setCountries (countries) {
    if (this.chronotruckProvider) this.chronotruckProvider.setCountries(countries)
    if (this.googleProvider) this.googleProvider.setCountries(countries)
  }
}
