var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('dashboard-card',{staticClass:"dashboard-express-quotation",class:{
    'is-disabled': _vm.isAccountLocked
  },attrs:{"title":_vm.$t('dashboard.titles.quotation'),"is-account-locked":_vm.isAccountLocked,"contact-form-link":_vm.contactFormLink},scopedSlots:_vm._u([{key:"locked-account-banner",fn:function(){return undefined},proxy:true},{key:"description",fn:function(){return [_c('p',{class:{
        'is-disabled': _vm.isAccountLocked
      }},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('dashboard.paragraphs.quotation.available_countries', {
          count: _vm.availableCountryCount
        }))}}),_c('span',{staticClass:"countries-link",class:{
          'ui-link': _vm.isAccountLocked
        },attrs:{"disabled":_vm.isAccountLocked},domProps:{"textContent":_vm._s(_vm.$t('dashboard.buttons.quotation.show_available_countries'))},on:{"click":_vm.showCountryList}})])]},proxy:true}])},[_c('div',{staticClass:"tw-flex tw-flex-col dashboard-express-quotation__wrapper"},[_c('dashboard-express-quotation-form',{staticClass:"tw-relative tw-flex-1",class:{
        'has-prices': _vm.getEstimation && _vm.getEstimation.prices && _vm.getEstimation.prices.length > 1
      },attrs:{"is-account-locked":_vm.isAccountLocked}}),(!_vm.isAccountLocked)?_c('dashboard-express-quotation-estimate',{staticClass:"tw-flex-1"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }