<template>
  <div class="dashboard-billing-amounts tw-flex">
    <div class="dashboard-billing-amounts__wrapper tw-flex tw-items-center">
      <template>
        <ctk-skeleton-view
          v-if="loading"
          class="tw-flex tw-flex-col tw-mr-4 tw-w-full"
        >
          <ctk-skeleton-block
            width="50%"
            height="20px"
            level="3"
            class="tw-mb-2"
            loading
          />
          <ctk-skeleton-block
            width="100%"
            height="36px"
            level="1"
            loading
          />
        </ctk-skeleton-view>
        <billing-invoice-amounts-header-section
          v-else
          :amount="$t('price_incl_tax', {
            price: $options.filters.currency(getInvoicesMetrics.amounts.payment_pending, 'EUR', this.$i18n.locale)
          })"
          class="tw-mr-8"
          data-test="pending"
        >
          <i18n
            path="billing.labels.amount_to_pay"
            tag="span"
            data-test="pending-content"
          >
            <template #invoices>
              <span
                v-text="$tc('billing.labels.invoices', getInvoicesMetrics.counts.payment_pending, {
                  count: getInvoicesMetrics.counts.payment_pending
                })"
                class="tw-font-medium"
              />
            </template>
          </i18n>
        </billing-invoice-amounts-header-section>
      </template>

      <template>
        <ctk-skeleton-view
          v-if="loading"
          class="tw-flex tw-flex-col tw-mr-4 tw-w-full"
        >
          <ctk-skeleton-block
            width="50%"
            height="20px"
            level="3"
            class="tw-mb-2"
            loading
          />
          <ctk-skeleton-block
            width="100%"
            height="36px"
            level="1"
            loading
          />
        </ctk-skeleton-view>
        <billing-invoice-amounts-header-section
          v-else
          :amount="$t('price_incl_tax', {
            price: $options.filters.currency(getInvoicesMetrics.amounts.payment_late, 'EUR', this.$i18n.locale)
          })"
          :tooltip="$t('billing.paragraphs.payment_delay')"
          primary
          data-test="late"
        >
          <span
            v-if="getInvoicesMetrics && getInvoicesMetrics.counts"
            v-markdown="$tc('billing.labels.amount_late', getInvoicesMetrics.counts.payment_late, {
              count: getInvoicesMetrics.counts.payment_late
            })"
            data-test="late-label"
          />
        </billing-invoice-amounts-header-section>
      </template>

      <template
        v-if="isBillingGrouped"
      >
        <ctk-skeleton-view
          v-if="loading"
          class="tw-flex tw-flex-col tw-w-full"
        >
          <ctk-skeleton-block
            width="50%"
            height="20px"
            level="3"
            class="tw-mb-2"
            loading
          />
          <ctk-skeleton-block
            width="100%"
            height="36px"
            level="1"
            loading
          />
        </ctk-skeleton-view>
        <billing-invoice-amounts-header-section
          v-else
          :amount="$t('price_incl_tax', {
            price: $options.filters.currency(getInvoicesMetrics.amounts.payment_proforma, 'EUR', this.$i18n.locale)
          })"
          :tooltip="$t('billing.paragraphs.outstanding_amount')"
          class="tw-ml-8"
          data-test="outstanding-amount"
        >
          <span
            v-text="$t('billing.labels.amount_outstanding')"
            class="tw-font-medium"
            data-test="outstanding-label"
          />
        </billing-invoice-amounts-header-section>
      </template>
    </div>
    <ctk-skeleton-block
      :loading="loading"
      width="100%"
      height="64px"
      level="3"
      class="dashboard-billing-amounts__explanation-wrapper tw-flex-1"
    >
      <billing-invoice-amounts-header-explanation
        :tooltip="$t('billing.paragraphs.payment_conditions_dashboard')"
        data-test="explanation"
      />
    </ctk-skeleton-block>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import BillingInvoiceAmountsHeaderSection from '@/views/Shippers/Billing/components/BillingInvoiceAmountsHeader/_subs/BillingInvoiceAmountsHeaderSection/index.vue'
  import BillingInvoiceAmountsHeaderExplanation from '@/views/Shippers/Billing/components/BillingInvoiceAmountsHeader/_subs/BillingInvoiceAmountsHeaderExplanation/index.vue'
  import CtkSkeletonView from '@/components/CtkSkeletonView/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'

  /**
   * @module component - DashboardBillingAmounts
   * @param {boolean} [loading=false]
   */
  export default defineComponent({
    name: 'DashboardBillingAmounts',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock,
      BillingInvoiceAmountsHeaderSection,
      BillingInvoiceAmountsHeaderExplanation
    },
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('auth', [
        'isBillingGrouped'
      ]),
      ...mapGetters('billing', [
        'getInvoicesMetrics'
      ])
    }
  })
</script>

<style lang="scss" scoped>
.dashboard-billing-amounts {
  padding: 20px 0;
  background: white;
}
.dashboard-billing-amounts__wrapper {
  flex: 0.666666;
  margin-right: 26px;
}
.dashboard-billing-amounts--has-outstanding .billing-invoice-amounts-header__wrapper {
  flex: 0.75;
}
.dashboard-billing-amounts .billing-invoice-amounts-header-section:not(:last-child) {
  position: relative;
}
.dashboard-billing-amounts .billing-invoice-amounts-header-section:not(:last-child)::after {
  position: absolute;
  content: '';
  right: 0;
  width: 1px;
  height: 100%;
  background-color: $divider;
}
.dashboard-billing-amounts__explanation-wrapper {
  flex: 0.333333;
}
.dashboard-billing-amounts .amounts-header__amount {
  font-size: 24px;
  font-weight: 300;
}
.dashboard-billing-amounts .amounts-header__explanation {
  background-color: $light-gray;
}
.dashboard-billing-amounts .amounts-header__explanation p {
  margin: auto;
  padding: 16px;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .dashboard-billing-amounts {
    flex-direction: column;
  }
  .dashboard-billing-amounts .amounts-header__explanation {
    padding: 16px;
  }
  .dashboard-billing-amounts__wrapper {
    margin-bottom: 16px;
    margin-right: 0;
  }
}
@media only screen and (max-width: $breakpoint-mobile-l) {
  .dashboard-billing-amounts__wrapper {
    display: block;
    width: 100%;
    padding: 20px 16px;
  }
  .dashboard-billing-amounts .billing-invoice-amounts-header-section {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .dashboard-billing-amounts .billing-invoice-amounts-header-section:not(:last-child) {
    margin-bottom: 32px;
  }
  .dashboard-billing-amounts .billing-invoice-amounts-header-section:not(:last-child)::after {
    left: 0;
    bottom: -16px;
    width: 100%;
    height: 1px;
  }
}
</style>
