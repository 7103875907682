<template>
  <dashboard-card
    :title="$t('dashboard.titles.quotation')"
    :is-account-locked="isAccountLocked"
    :contact-form-link="contactFormLink"
    class="dashboard-express-quotation"
    :class="{
      'is-disabled': isAccountLocked
    }"
  >
    <template
      v-slot:locked-account-banner
    />
    <template
      v-slot:description
    >
      <p
        :class="{
          'is-disabled': isAccountLocked
        }"
      >
        <span
          v-text="$t('dashboard.paragraphs.quotation.available_countries', {
            count: availableCountryCount
          })"
        />
        <span
          v-text="$t('dashboard.buttons.quotation.show_available_countries')"
          class="countries-link"
          :class="{
            'ui-link': isAccountLocked
          }"
          :disabled="isAccountLocked"
          @click="showCountryList"
        />
      </p>
    </template>

    <div class="tw-flex tw-flex-col dashboard-express-quotation__wrapper">
      <dashboard-express-quotation-form
        :is-account-locked="isAccountLocked"
        :class="{
          'has-prices': getEstimation && getEstimation.prices && getEstimation.prices.length > 1
        }"
        class="tw-relative tw-flex-1"
        data-test="form"
      />
      <dashboard-express-quotation-estimate
        v-if="!isAccountLocked"
        class="tw-flex-1"
        data-test="estimate"
      />
    </div>
  </dashboard-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import chronoinfos from '@/../config/chronoinfos'

  import { EventBus } from '@/services/EventBus'
  import useCountryLists from '@/composables/useCountryLists'

  import DashboardCard from './../DashboardCard/index.vue'
  import DashboardExpressQuotationForm from './_subs/DashboardExpressQuotationForm/index.vue'
  import DashboardExpressQuotationEstimate from './_subs/DashboardExpressQuotationEstimate/index.vue'

  /**
   * @module component - DashboardExpressQuotation
   */
  export default defineComponent({
    name: 'DashboardExpressQuotation',
    components: {
      DashboardCard,
      DashboardExpressQuotationForm,
      DashboardExpressQuotationEstimate
    },
    methods: {
      ...mapActions('shipments', [
        'resetEstimation'
      ]),
      /**
       * @function compare
       */
      showCountryList () {
        EventBus.$emit('dashboard:dialogs:open-dialog', {
          dialog: 'countryList'
        })
      }
    },
    mounted () {
      this.resetEstimation()
    },
    computed: {
      ...mapGetters([
        'isUserShipper'
      ]),
      ...mapGetters('auth', [
        'getUserInfos',
        'isAccountLocked',
        'isImpersonating'
      ]),
      ...mapGetters('shipments', [
        'getEstimation'
      ]),
      availableCountryCount () {
        const { getAvailableCountries } = useCountryLists()
        return getAvailableCountries().length
      },
      contactFormLink () {
        const shipmentContactForm = chronoinfos.forms.contact.shipment
        const isFrenchShipper = !this.isImpersonating && this.isUserShipper && this.getUserInfos.locale.slice(0, 2) === 'fr'
        const contactFormLocale = isFrenchShipper ? 'fr' : 'default'

        return shipmentContactForm[contactFormLocale]
      }
    }
  })
</script>

<style lang="scss">

  .dashboard-express-quotation .dashboard-card__header {
    margin-bottom: 20px;
  }

</style>

<style lang="scss" scoped>
.dashboard-express-quotation .dashboard-express-quotation-form {
  margin-bottom: 50px;
}
.dashboard-express-quotation .dashboard-express-quotation-form::before {
  position: absolute;
  left: 0;
  content: '';
  bottom: -25px;
  width: 100%;
  height: 1px;
  background-color: $divider;
}
.dashboard-express-quotation .dashboard-express-quotation-form[disabled]::before {
  content: none;
}
.dashboard-express-quotation .countries-link {
  text-decoration: underline;
}
.dashboard-express-quotation .countries-link:not([disabled=true]) {
  cursor: pointer;
  margin-left: 0.25rem;
}
.dashboard-express-quotation .is-disabled {
  opacity: 0.7;
  filter: grayscale(1);
  cursor: not-allowed;
}
</style>
