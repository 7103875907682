<template>
  <div class="dashboard-express-quotation-estimate tw-relative">
    <div
      :class="{
        'xl:tw-flex-row': hasPrice && getEstimation.prices.length > 1,
        'md:tw-flex-row': hasPrice && getEstimation.prices.length < 2
      }"
      class="dashboard-express-quotation-estimate__price tw-flex tw-flex-col md:tw-items-start md:tw-justify-between tw-mb-4"
    >
      <template
        v-if="$wait.is('fetching estimate price')"
      >
        <ui-loader
          :size="40"
          background-back="#000"
          type="pallet"
          data-test="loader"
        />
      </template>
      <template
        v-else
      >
        <template
          v-if="hasPrice"
        >
          <div
            class="dashboard-express-quotation-estimate__prices--multiple tw-flex tw-flex-col md:tw-flex-row tw-w-full tw-justify-between"
            data-test="prices"
          >
            <dashboard-express-quotation-estimate-price
              v-for="price in getEstimation.prices"
              :key="price.type"
              :name="offers[price.type]"
              :price="price.total"
              :info="$t(price.type === 'pallet_network'
                ? 'dashboard.titles.quotation.info.pallet_network'
                : 'dashboard.titles.quotation.info.chronotruck')"
              :currency="price.currency"
              :is-offer-customisable="getCustomOffer && price.type === 'chronotruck'"
              :data-test="price.type"
              :class="{
                'md:tw-w-1/2': getEstimation.prices.length > 1
              }"
            />
          </div>
        </template>
        <div
          v-else
          class="dashboard-express-quotation-estimate__prices tw-flex tw-flex-col"
          data-test="empty"
        >
          <h3
            class="dashboard-express-quotation-estimate__title tw-font-bold tw-text-base tw-mr-2"
            data-test="empty-title"
            v-text="$t('dashboard.titles.quotation.estimate')"
          />

          <div
            class="tw-text-blue-500 tw-font-light tw-text-lg tw-text-3xl"
            v-text="$t('price_excl_tax', {
              price: '- €'
            })"
            data-test="empty-price"
          />
        </div>
      </template>
    </div>
    <div class="tw-flex tw-flex-row-reverse">
      <ctk-btn-quotation-refine
        data-test="refine-button"
        v-if="hasPrice"
      >
        {{ $t('dashboard.buttons.quotation.refine') }}
      </ctk-btn-quotation-refine>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { offers } from '@/../config/chronoinfos'

  import DashboardExpressQuotationEstimatePrice from './_subs/DashboardExpressQuotationEstimatePrice'
  import CtkBtnQuotationRefine from '@/components/CtkBtnQuotationRefine/index.vue'

  /**
   * @module component - DashboardExpressQuotationEstimate
   */
  export default {
    name: 'DashboardExpressQuotationEstimate',
    components: {
      CtkBtnQuotationRefine,
      DashboardExpressQuotationEstimatePrice
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getEstimation'
      ]),
      ...mapGetters('auth', [
        'getFeatureFlags'
      ]),
      /**
       * Returns true if the quotation has at least one price
       * @function hasPrice
       * @returns {boolean}
       */
      hasPrice () {
        return this.getEstimation && this.getEstimation.prices && this.getEstimation.prices.length
      },
      getCustomOffer () {
        return !!this.getFeatureFlags?.custom_offer
      }
    }
  }
</script>

<style lang="scss" scoped>
.dashboard-express-quotation-estimate__title {
  color: $secondary-text;
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:nth-child(1) {
  margin-right: 2rem;
}
@media (min-width: 375px) {
  .dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:nth-child(1) {
    margin-right: 0;
  }
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:nth-child(2):last-child {
  padding-right: 0;
  border-top: 1px solid $divider;
  padding-top: 1.375rem;
  margin-top: 1.375rem;
}
@media (min-width: 770px) {
  .dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:nth-child(2):last-child {
    padding-right: 0;
    padding-top: 0;
    padding-left: 2rem;
    margin-top: 0;
    border-top: none;
    border-left: 1px solid $divider;
  }
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:nth-child(2):last-child::after {
  position: absolute;
  content: '';
  top: -35px;
  left: 30px;
  width: 20px;
  height: 20px;
  transform: rotate(135deg);
  background-color: white;
  border-top: 1px solid $divider;
  border-right: 1px solid $divider;
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:not(:last-child):not(:nth-last-child(2):first-child) {
  border-bottom: 1px solid $divider;
  padding-bottom: 1.375rem;
  margin-bottom: 1.375rem;
}
@media (min-width: 770px) {
  .dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:not(:last-child):not(:nth-last-child(2):first-child) {
    border-bottom: none;
    border-right: 1px solid $divider;
    margin: 0;
    padding: 0;
    padding-right: 30px;
    padding-left: 30px;
  }
}
.dashboard-express-quotation-estimate .dashboard-express-quotation-estimate-price:not(:last-child):not(:nth-last-child(2):first-child)::after {
  position: absolute;
  content: '';
  top: -35px;
  left: 60px;
  width: 20px;
  height: 20px;
  transform: rotate(135deg);
  background-color: white;
  border-top: 1px solid $divider;
  border-right: 1px solid $divider;
}
</style>
