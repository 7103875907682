<template>
  <div
    class="dashboard-express-quotation-form"
    :disabled="isAccountLocked"
  >
    <ValidationObserver
      ref="my-observer"
      slim
    >
      <template slot-scope="{ invalid: invalidForm }">
        <ValidationProvider
          ref="pickup-address-provider"
          :name="$t('new-shipment.labels.search_address.pickup_address')"
          class="tw-flex"
          slim
        >
          <template slot-scope="{ invalid: invalidPickup, errors: pickupErrors }">
            <ctk-input-address
              ref="pickup-address-input"
              v-model="formData.pickupString"
              :disabled="isAccountLocked"
              :direction="'pickup'"
              :provider="provider"
              :disabled-countries="disabledCountries('pickup')"
              :label="$t('dashboard.labels.quotation.pickup_address')"
              :error="pickupErrors.pickup || pickupErrors[0]"
              :country.sync="countries.pickup"
              id="pickup"
              data-test="pickup-address"
              class="pickup-address-input tw-mb-2 tw-flex-1"
              @click.native="initiate"
              @autocomplete="v => updateAddress('pickup', v)"
              @clear="clearPrice('pickup')"
            />
            <dashboard-express-quotation-form-help
              :has-error="invalidPickup"
              :title="$t('dashboard.labels.quotation.address')"
              :content="$t('dashboard.paragraphs.quotation.address_info')"
              data-test="pickup-address-help"
            />
          </template>
        </ValidationProvider>

        <ValidationProvider
          ref="delivery-address-provider"
          :name="$t('new-shipment.labels.search_address.delivery_address')"
          class="tw-flex"
          slim
        >
          <template slot-scope="{ invalid: invalidDelivery, errors: deliveryErrors }">
            <ctk-input-address
              ref="delivery-address-input"
              v-model="formData.deliveryString"
              :disabled="isAccountLocked"
              :direction="'delivery'"
              :provider="provider"
              :disabled-countries="disabledCountries('delivery')"
              :label="$t('dashboard.labels.quotation.delivery_address')"
              :error="deliveryErrors.delivery || deliveryErrors[0]"
              :country.sync="countries.delivery"
              id="delivery"
              data-test="delivery-address"
              class="delivery-address-input tw-mb-2 tw-flex-1"
              @click.native="initiate"
              @autocomplete="v => updateAddress('delivery', v)"
              @clear="clearPrice('delivery')"
            />
            <dashboard-express-quotation-form-help
              :has-error="invalidDelivery"
              :title="$t('dashboard.labels.quotation.address')"
              :content="$t('dashboard.paragraphs.quotation.address_info')"
              data-test="delivery-address-help"
            />
          </template>
        </ValidationProvider>

        <dashboard-express-quotation-form-goods
          v-model="formData.goods"
          :disabled="isAccountLocked || invalidForm"
          ref="goods"
          data-test="goods"
          @click.native="initiate"
          @input="submitted"
        />
      </template>
    </ValidationObserver>
  </div>
</template>

<script>
  import Hotjar from '@/plugins/VueHotjar'
  import { mapActions, mapGetters } from 'vuex'

  import { validate } from 'vee-validate'

  import useCountryLists from '@/composables/useCountryLists'
  import { showToaster } from '@/services/Toaster'
  import CtkInputAddress from '@/components/CtkInputs/CtkInputAddress'
  import EstimationAddressProvider from '@/providers/AddressProvider/EstimationAddressProvider'
  import DashboardExpressQuotationFormGoods from './_subs/DashboardExpressQuotationFormGoods'
  import DashboardExpressQuotationFormHelp from './_subs/DashboardExpressQuotationFormHelp/index.vue'

  const defaultFormData = {
    pickup: null,
    pickupString: null,
    delivery: null,
    deliveryString: null,
    goods: null
  }

  /**
   * @module component - DashboardExpressQuotationForm
   */
  export default {
    name: 'DashboardExpressQuotationForm',
    props: {
      isAccountLocked: {
        type: Boolean,
        default: false
      }
    },
    components: {
      CtkInputAddress,
      DashboardExpressQuotationFormGoods,
      DashboardExpressQuotationFormHelp
    },
    data () {
      return {
        provider: new EstimationAddressProvider(),
        hasMadeAction: false,
        formData: JSON.parse(JSON.stringify(defaultFormData)),
        errors: {
          pickup: null,
          delivery: null
        },
        countries: {
          pickup: 'FR',
          delivery: 'FR'
        }
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getEstimation'
      ]),
      /**
       * Returns a list of disabled countries according to either the pickup/delivery country
       * or the pickup/delivery address's country
       * @function disabledCountries
       * @returns {Array} countries
       */
      disabledCountries () {
        const { getAvailableCountries } = useCountryLists()
        const availableCountries = getAvailableCountries()

        const unauthorizedSameCountries = availableCountries
          .filter(country => country['disabled-for-home'])
          .map(country => country['iso-2'])

        return direction => {
          const { pickup, delivery } = this.formData
          const isPickupAddressCountryUnauth = pickup && unauthorizedSameCountries.includes(pickup.country)
          const isPickupSelectedCountryUnauth = !!this.countries.pickup && unauthorizedSameCountries.includes(this.countries.pickup)

          const isDeliveryAddressCountryUnauth = delivery && unauthorizedSameCountries.includes(delivery.country)
          const isDeliverySelectedCountryUnauth = !!this.countries.delivery && unauthorizedSameCountries.includes(this.countries.delivery)

          if (direction === 'delivery' && (isPickupAddressCountryUnauth || isPickupSelectedCountryUnauth)) {
            return [isPickupAddressCountryUnauth ? pickup.country : this.countries.pickup]
          }

          if (direction === 'pickup' && (isDeliveryAddressCountryUnauth || isDeliverySelectedCountryUnauth)) {
            return [isDeliveryAddressCountryUnauth ? delivery.country : this.countries.delivery]
          }

          return []
        }
      }
    },
    async mounted () {
      await this.$nextTick()
      if (this.$refs.observer && this.$refs.observer.reset) this.$refs.observer.reset()
      if (this.$refs['delivery-observer'] && this.$refs['delivery-observer'].reset) this.$refs['delivery-observer'].reset()
    },
    methods: {
      ...mapActions('shipments', [
        'resetEstimation',
        'retrieveEstimation'
      ]),
      initiate () {
        if (!this.hasMadeAction) {
          this.hasMadeAction = true

          Hotjar.tag('Shipper Estimate')

          if (this.$matomo) {
            this.$matomo.trackEvent('Estimate', 'Initiate')
          }
        }
      },
      async updateAddress (direction, address) {
        const { isCountryOpened } = useCountryLists()

        this.formData[direction] = address
        this.errors[direction] = null

        const { city, postalCode, country, hasRequiredComponents } = address.address

        const validAddress = await validate('required', `country:${country}`)

        if (!validAddress.valid) {
          this.$refs[`${direction}-address-provider`].setErrors([
            this.$t('validator.country')
          ])

          this.setAddressError(direction, true)
          return
        }

        if (country && !isCountryOpened(country)) {
          this.$refs[`${direction}-address-provider`].setErrors([
            this.$t('validator.country')
          ])

          this.setAddressError(direction, true)

          return
        }

        /**
         * TODO: Find a better way to handle missing address components.
         * hasRequiredComponents is used when the entire address is required
         * but here only the city, country & postal code are required.
         */
        const hasEstimationComponents = !!city && !!postalCode && !!country
        if (hasRequiredComponents === false || !hasEstimationComponents) {
          const errorMessage = !postalCode
            ? this.$t('app.paragraphs.error.quotation_express.address_not_precise')
            : this.$t('app.paragraphs.error.address_not_precise')

          this.$refs[`${direction}-address-provider`].setErrors([
            errorMessage
          ])

          this.setAddressError(direction, true)

          this.errors[direction] = errorMessage
        }

        this.submitted()
      },
      /**
       * Called whenever the user clears the pickup or the delivery address
       * @function clearPrice
       */
      clearPrice (direction) {
        this.formData[direction] = null
        this.formData[`${direction}String`] = null
        this.errors[direction] = null

        this.resetEstimation()
      },
      submitted () {
        const { pickup, delivery, goods } = this.formData
        const hasPickup = pickup && !!pickup.address &&
          !!pickup.address.postalCode && !!pickup.address.country && !!pickup.address.location
        const hasDelivery = delivery && !!delivery.address &&
          !!delivery.address.postalCode && !!delivery.address.country && !!delivery.address.location
        const hasGoods = !!goods

        if (!hasPickup || !hasDelivery || !hasGoods) return false

        this.$wait.start('fetching estimate price')
        this.retrieveEstimation({
          pickup: pickup,
          delivery: delivery,
          goods
        })
          .then(() => {
            if (this.$matomo) {
              const prices = this.getEstimation.prices && this.getEstimation.prices.map(price => price.type).join('|')
              this.$matomo.trackEvent('Estimate', 'Done', prices)
            }
          })
          .catch(async err => {
            if (!err.response) return

            const { data } = err.response
            if (data && data.error) {
              if (data.error.violations) {
                const loadViolations = data.error.violations
                  .filter(violation => violation.property_path.includes('load.'))

                /**
                 * If there is any violation in the load, open the dropdown to
                 * show the errors in the appropriate fields.
                 */
                if (loadViolations.length > 0) {
                  this.$refs.goods && this.$refs.goods.show()
                  await this.$nextTick()
                  loadViolations
                    .forEach(violation => {
                      const propertyPath = violation.property_path.replace('load.', '')
                      if (this.$refs.goods && this.$refs.goods.$refs.dropdown && this.$refs.goods.$refs.dropdown.$refs[`${propertyPath}-provider`]) {
                        this.$refs.goods.$refs.dropdown.$refs[`${propertyPath}-provider`].setErrors([
                          violation.message
                        ])
                      }
                    })
                }
              } else {
                const errorMessage = data.error.detail || data.error.title
                showToaster(this, errorMessage, {
                  type: 'error',
                  position: 'bottom-right'
                })
              }
            }
          })
          .finally(() => {
            this.$wait.end('fetching estimate price')
          })
      },
      setAddressError (direction, v, cb) {
        const input = this.$refs[`${direction}-address-input`]
        if (input) {
          if (cb) cb(input)
        }
      }
    }
  }
</script>
