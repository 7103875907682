<template>
  <div class="dashboard-card tw-bg-white">
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-justify-between dashboard-card__header">
      <slot name="header-title">
        <header class="tw-w-full">
          <div class="tw-flex tw-items-center">
            <h2
              class="dashboard-card__header__title tw-mb-4"
              v-text="title"
            />
            <ui-material-icon
              v-if="tooltip"
              v-b-tooltip.hover.html="tooltipOptions"
              name="info"
              class="tw-text-base tw-align-middle tw-ml-1 tw-mb-4"
              @click.native="click"
              data-test="tooltip"
            />
          </div>
          <slot
            v-if="isAccountLocked"
            name="locked_banner"
          >
            <div class="locked-account-banner">
              <span>{{ accountLockedExplanation }}</span>&nbsp;<a :href="contactFormLink">{{ contactUs }}</a>
            </div>
          </slot>
        </header>
      </slot>
      <slot name="header-buttons" />
    </div>
    <div class="dashboard-card__content">
      <slot name="description" />

      <slot />
    </div>
  </div>
</template>

<script>
  import { computed, defineComponent, ref, toRefs } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'
  import useI18n from '@/composables/useI18n'

  /**
   * @module component - DashboardCard
   * @param {string} title
   * @param {string} [tooltip=null]
   */
  export default defineComponent({
    name: 'DashboardCard',
    props: {
      title: {
        type: String,
        default: null
      },
      tooltip: {
        type: String,
        default: null
      },
      isAccountLocked: {
        type: Boolean,
        default: false
      },
      contactFormLink: {
        type: String,
        default: null
      }
    },
    setup (props) {
      const { tooltip } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(null, tooltip.value)
      const i18n = useI18n()

      const contactUs = ref(i18n.value.t('app.paragraphs.account_locked.contact_us'))
      const accountLockedExplanation = ref(i18n.value.t('dashboard.paragraphs.quotation.account_locked'))

      const tooltipOptions = computed(() => ({
        title: tooltip.value,
        disabled: haveDialog.value
      }))

      return {
        accountLockedExplanation,
        click,
        contactUs,
        haveDialog,
        tooltipOptions
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-card {
    padding: 30px 40px;
    border: 1px solid $divider;

    &__header {
      &__title {
        position: relative;
        font-size: 20px;
        font-weight: 400;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -4px;
          width: 200px;
          height: 1px;
          background-color: $divider;
        }
      }
    }

    .locked-account-banner {
      padding: 0.75rem 1rem;
      color: #763336;
      background-color: #EDCACF;
      border-radius: 0.25rem;

      a {
        color: inherit;
        font-weight: 500;
        font-style: italic;
        text-decoration: underline;
      }
    }

    &.is-disabled {
      .dashboard-card__header__title,
      .dashboard-card__content {
        color: #676A6C;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px;

      &__header__title::after {
        width: 100%;
      }
    }
  }

</style>
