<template>
  <div class="dashboard-statistics-item tw-flex tw-flex-col tw-p-4">
    <template
      v-if="loading"
    >
      <ctk-skeleton-block
        :level="2"
        :loading="true"
        width="30px"
        height="30px"
        class="tw-mb-2"
      />
      <ctk-skeleton-block
        :level="2"
        :loading="true"
        width="70px"
        height="35px"
        class="tw-mb-2"
      />
      <ctk-skeleton-block
        :level="2"
        :loading="true"
        width="45px"
        height="18px"
      />
    </template>
    <template
      v-else
    >
      <ui-ctk-icon
        :name="icon"
        class="tw--ml-2 tw--mt-2 tw-text-secondary-lighten tw-text-4xl"
        data-test="icon"
      />
      <div class="tw-flex tw-items-end tw-text-blue-500 tw-leading-tight">
        <div
          v-text="value === null ? '-' : value"
          class="tw-text-3xl tw-mr-2"
          data-test="value"
        />
        <div
          v-if="unit"
          v-text="unit"
          class="tw-text-xs tw-mb-1"
          data-test="unit"
        />
      </div>
      <div class="tw-inline-flex tw-items-center">
        <span
          v-text="label"
          class="tw-text-sm tw-text-secondary-text"
          data-test="label"
        />
        <ui-material-icon
          v-if="tooltip"
          v-b-tooltip.hover.html="tooltipOptions"
          name="info"
          class="tw-text-base tw-align-middle tw-ml-1 tw-text-secondary-text"
          @click.native="click"
          data-test="tooltip"
        />
      </div>
    </template>
  </div>
</template>

<script>
  import { defineComponent, computed, toRefs } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'

  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'

  /**
   * @module component - DashboardStatisticsItem
   * @param {string} icon
   * @param {string} label
   * @param {string} [unit=null]
   * @param {string} [tooltip=null]
   * @param {string} [value=null]
   * @param {boolean} [loading=false]
   */
  export default defineComponent({
    components: {
      CtkSkeletonBlock
    },
    props: {
      icon: {
        type: String,
        required: true
      },
      label: {
        type: String,
        required: true
      },
      unit: {
        type: String,
        default: null
      },
      tooltip: {
        type: String,
        default: null
      },
      value: {
        type: String,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    setup (props) {
      const { tooltip } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(null, tooltip.value)

      const tooltipOptions = computed(() => ({
        title: tooltip.value,
        disabled: haveDialog.value
      }))

      return {
        click,
        haveDialog,
        tooltipOptions
      }
    }
  })
</script>

<style lang="scss" scoped>

  .dashboard-statistics-item {
    background-color: #F9F9F9;
  }

</style>
