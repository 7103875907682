<template>
  <div
    class="dashboard-express-quotation-form-goods"
    v-click-outside="close"
  >
    <dashboard-express-quotation-form-goods-button
      ref="button"
      :goods="value"
      :disabled="disabled"
      @click.native="show"
      @keyup.native="checkShow"
    />
    <transition name="slide">
      <dashboard-express-quotation-form-goods-dropdown
        ref="dropdown"
        v-if="isOpen"
        :value="tempLoad"
        @re-focus="refocus"
        @validate="validate"
        @close="close"
      />
    </transition>
  </div>
</template>

<script>
  import { KEYCODES } from '@/composables/constants'

  import DashboardExpressQuotationFormGoodsButton from './_subs/DashboardExpressQuotationFormGoodsButton'
  import DashboardExpressQuotationFormGoodsDropdown from './_subs/DashboardExpressQuotationFormGoodsDropdown'
  import { directive } from 'v-click-outside'

  /**
   * @module component - DashboardExpressQuotationFormGoods
   * @param {object} value
   */
  export default {
    name: 'DashboardExpressQuotationFormGoods',
    components: {
      DashboardExpressQuotationFormGoodsButton,
      DashboardExpressQuotationFormGoodsDropdown
    },
    directives: {
      clickOutside: directive
    },
    props: {
      value: {
        type: Object,
        default: null
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        goods: null,
        isOpen: false,
        tempLoad: null
      }
    },
    methods: {
      /**
       * @function checkShow
       * @param {KeyboardEvent} e
       */
      checkShow (e) {
        const key = e.which || e.keyCode
        switch (key) {
        case KEYCODES.UP:
        case KEYCODES.DOWN:
          e.preventDefault()
          this.show()
          break
        }
      },
      refocus () {
        this.$refs.button.$el.focus()
      },
      close () {
        if (this.$refs.dropdown && this.$refs.dropdown.formData) {
          this.tempLoad = this.$refs.dropdown.formData
        }

        this.isOpen = false
      },
      async show () {
        if (this.disabled) {
          return
        }

        this.isOpen = true
        await this.$nextTick()
        this.$refs.dropdown.$el.focus()
      },
      validate (v) {
        this.tempLoad = v
        this.$emit('input', v)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .dashboard-express-quotation-form-goods {
    position: relative;

    .dashboard-express-quotation-form-goods-dropdown {
      position: absolute;
      width: 100%;
    }

    .slide-enter-active,
    .slide-leave-active {
      opacity: 1;
      transition: all 200ms ease-in-out;
      transform: translateY(0);
    }

    .slide-enter,
    .slide-leave-to {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

</style>
