<template>
  <ctk-dialog
    v-model="dialogValue"
    id="country-list"
    modal-class="new-shipment-quotation-country-list-dialog"
    max-width="475px"
    hide-header
    hide-footer
    persistent
  >
    <div class="tw-p-4 md:tw-p-10">
      <h1
        v-text="$t('new-shipment.titles.add_address.countries_available')"
        class="new-shipment-quotation-country-list-dialog__title tw-relative tw-text-2xl tw-font-medium tw-mb-12 tw-mr-2"
        data-test="title"
      />

      <ctk-dialog-close-button
        class="tw-top-0 tw-right-0"
        data-test="close"
        @click.native="dialogValue = false"
      />

      <new-shipment-address-available-countries-list
        :title="null"
        class="dialog-country-list"
      />
    </div>
  </ctk-dialog>
</template>

<script>
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import NewShipmentAddressAvailableCountriesList from '@/views/Shippers/NewShipment/_subs/NewShipmentAddress/components/NewShipmentAddressAdd/_subs/NewShipmentAddressAvailableCountries/_subs/NewShipmentAddressAvailableCountriesList'

  /**
   * @module component - DashboardExpressQuotationCountryListDialog
   */
  export default {
    name: 'DashboardExpressQuotationCountryListDialog',
    components: {
      CtkDialog,
      CtkDialogCloseButton,
      NewShipmentAddressAvailableCountriesList
    },
    props: {
      value: {
        type: Boolean,
        default: true
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    }
  }
</script>

<style lang="scss" scoped>

  .new-shipment-quotation-country-list-dialog {
    &__title {
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -0.5rem;
        width: 190px;
        height: 1px;
        background-color: $secondary-text;
      }
    }

    .new-shipment-address-available-countries-list {
      background-color: inherit;
      padding: 0;

      &__item {
        cursor: default;
        pointer-events: none;
        user-select: none;
      }
    }
  }

</style>
