<template>
  <div class="survey-banner">
    <img
      :src="require('@/assets/img/icons/messaging.svg')"
      width="80"
      height="80"
      class="survey-banner__logo"
      data-test="logo"
    >
    <div class="survey-banner__title__wrapper">
      <h2
        class="survey-banner__title"
        v-text="title"
        data-test="title"
      />
      <p
        class="survey-banner__description"
        v-text="desc"
        data-test="description"
      />
    </div>
    <ui-button
      v-if="surveyLink"
      :href="surveyLink"
      size="sm"
      variant="primary"
      rel="noopener"
      target="_blank"
      class="survey-banner__btn"
      data-test="link"
    >
      {{ startSurveyBtnlabel }}
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  export default defineComponent({
    name: 'DashboardSurveyBanner',
    props: {
      surveyLink: {
        type: String,
        default: null
      }
    },
    data () {
      return {
        title: this.$t('dashboard.titles.survey'),
        desc: this.$t('dashboard.paragraphs.survey'),
        startSurveyBtnlabel: this.$t('dashboard.buttons.survey.start')
      }
    }
  })
</script>

<style lang="scss" scoped>
.survey-banner {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  --tw-border-opacity: 1;
  border-color: rgba(227, 227, 229, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding: 1.25rem;
}
@media (min-width: 770px) {
  .survey-banner {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.survey-banner__logo {
  margin-right: 30px;
  margin-bottom: 1rem;
}
@media (min-width: 770px) {
  .survey-banner__logo {
    margin-bottom: 0px;
  }
}
.survey-banner__title__wrapper {
  margin-bottom: 1rem;
  max-width: 100%;
}
@media (min-width: 770px) {
  .survey-banner__title__wrapper {
    margin-bottom: 0px;
    max-width: 530px;
  }
}
.survey-banner__title {
  line-height: 26px;
  margin-bottom: 1.25rem;
  width: 20ch;
  position: relative;
  font-size: 20px;
  font-weight: 400;
}
@media (min-width: 375px) {
  .survey-banner__title {
    width: 100%;
  }
}
.survey-banner__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -9px;
  width: 200px;
  height: 1px;
  background-color: $divider;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .survey-banner__title::after {
    bottom: -4px;
  }
}
.survey-banner__description {
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(117, 116, 116, var(--tw-text-opacity));
}
@media (min-width: 770px) {
  .survey-banner__description {
    line-height: 18px;
  }
}
.survey-banner__btn {
  margin-top: auto;
  width: 100%;
}
@media (min-width: 770px) {
  .survey-banner__btn {
    margin-left: auto;
    width: auto;
  }
}
</style>
