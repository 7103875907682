<template>
  <ctk-dialog
    v-model="dialogValue"
    id="custom-offer-dialog"
    modal-class="new-shipment-quotation-custom-offer-dialog"
    max-width="620px"
    hide-header
    hide-footer
    add-close-modal-event
  >
    <div class="tw-p-10 md:tw-p-10">
      <div class="tw-flex tw-flex-col tw-text-center">
        <div class="tw-items-center tw-justify-center tw-my-5">
          <img
            src="@/assets/img/icons/target.svg"
            alt=""
            width="80"
            data-test="image"
          >
        </div>

        <h2
          class="tw-mt-6"
          data-test="title"
        >
          {{ offerLabel }}
        </h2>
        <p
          class="custom-offer-modal-text tw-mt-5"
          data-test="text"
        >
          {{ $t('new-shipment.custom_offer.modal.text') }}
        </p>
        <div class="tw-my-5 tw-flex tw-justify-evenly">
          <ctk-btn-quotation-refine data-test="refine-button">
            {{ $t('dashboard.buttons.quotation.refine') }}
          </ctk-btn-quotation-refine>
        </div>
      </div>
    </div>

    <ctk-dialog-close-button
      class="tw-top-2 tw-right-2"
      @click.native="dialogValue = false"
      data-test="close"
    />
    <div class="footer-rectangle tw-pt-5" />
  </ctk-dialog>
</template>

<script>
  import CtkDialog from '@/components/CtkDialog/index.vue'
  import CtkDialogCloseButton from '@/components/CtkDialog/_subs/CtkDialogCloseButton/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'
  import { EventBus } from '@/services/EventBus'
  import CtkBtnQuotationRefine from '@/components/CtkBtnQuotationRefine/index.vue'

  /**
   * @module component - NewShipmentQuotationCompareDialog
   * @param {boolean} [hasPrice=true] - Specify if the price should be shown or not
   * @param {boolean} [hasActions=true] - Specify if the action buttons should be shown or not
   */
  export default {
    name: 'NewShipmentQuotationCustomOfferDialog',
    components: {
      CtkBtnQuotationRefine,
      CtkDialog,
      CtkDialogCloseButton
    },
    props: {
      value: {
        type: Boolean,
        default: true
      },
      offerLabel: {
        type: String,
        required: true
      },
      origin: {
        type: String,
        default: 'Quotations'
      }
    },
    setup (props) {
      const { state: dialogValue } = useModelGetterSetter(props, 'value')

      return {
        dialogValue
      }
    },
    mounted () {
      EventBus.$on('add-close-modal-event', () => {
        // Refresh Hubspot widget to close the dedicated custom offer chat
        window.history.pushState({}, 'hs_custom_offer', '?hs_custom_offer=false')
        window.HubSpotConversations.widget.refresh({ openToNewThread: true })
      })
    }
  }
</script>

<style lang="scss">

.new-shipment-quotation-custom-offer-dialog .custom-offer-modal-text {
  color: $secondary-text;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.new-shipment-quotation-custom-offer-dialog .buttons {
  padding-left: 60px;
  padding-right: 60px;
}

.new-shipment-quotation-custom-offer-dialog .custom-offer-call-button {
  margin-right: 20px;
  background: $divider;
  color: $info;

  &:hover {
    color: $info-darken;
    background: $gray;
  }
}

.new-shipment-quotation-custom-offer-dialog .footer-rectangle {
  background: $primary;
}


</style>
